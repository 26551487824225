/* eslint-disable no-case-declarations */
/*
  *demo
  bill_yang 2021.09
*/
import { TOGGLE_COUPON } from 'redux/constant';
import { setLocalStorage } from '../../untils/auth';
// 登录注册
export function getLoginShow(preState = false, action) {
  let { type } = action;
  switch (type) {
    case 'open':
      return true;
    case 'close':
      return false;
    default:
      return preState;
  }
}
// loading
export function getLoading(preState = false, action) {
  let { type } = action;
  switch (type) {
    case 'true':
      return true;
    case 'false':
      return false;
    default:
      return preState;
  }
}
localStorage.getItem('token');
// token
let token = !localStorage.getItem('token') ? false : localStorage.getItem('token');
export function getToken(preState = token, action) {
  let { type, data } = action;
  switch (type) {
    case 'token':
      setLocalStorage('token', data);
      return data;
    default:
      return preState;
  }
}

//航班日期选择
let initDateState = {
  tripOnce: 0,
  startDate: '',
  startFromTime: '',
  startToTime: '',
  endDate: '',
  endFromTime: '',
  endToTime: '',
};
export function getTripDate(preState = initDateState, action) {
  let { type } = action;
  switch (type) {
    case 'change_date':
      let newState = JSON.parse(JSON.stringify(preState));
      newState = action.data;
      return newState;
    case 'clear_date':
      return initDateState;
    default:
      return preState;
  }
}

// 会员卡用户填写信息
let memberDateState = {
  name: '',
  email: '',
  phone: '',
  phoneCode: 1,
  addressStreet: '',
  addressHouseNumber: '',
  city: '',
  country: '',
  ziplpostalCode: '',
  emergencyContact: '',
  emergencyPhone: '',
  emergencyPhoneCode: 1,
  couponIds: '',
  state: '',
  countryCode: 'US',
  contactCountryCode: 'US',
};
export function getMemberDate(preState = memberDateState, action) {
  let { type } = action;
  switch (type) {
    case 'change_date':
      let newState = JSON.parse(JSON.stringify(preState));
      newState = action.data;
      return newState;
    case 'clear_date':
      return memberDateState;
    default:
      return preState;
  }
}

//blog category tags
let categoryTags = {};
export function getCategoryTags(preState = categoryTags, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_tag':
      return data;
    default:
      return preState;
  }
}

//blog category tags
let blogSearchKeyword = '';
export function getBlogSearchkeyword(preState = blogSearchKeyword, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_keyword':
      return data;
    case 'clear_keyword':
      return blogSearchKeyword;
    default:
      return preState;
  }
}

// Fuzzy search
let searchData = [];
export function getFuzzySearchData(preState = searchData, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_fuzzy_search':
      return data;
    default:
      return preState;
  }
}

// 包机inquire
export function getCharterQuoteChange(preState = false, action) {
  let { type } = action;
  switch (type) {
    case 'charterQuoteSumbit':
      return true;
    case 'charterQuoteClear':
      return false;
    default:
      return preState;
  }
}

// 空腿inquire
export function getEmptyLegChange(preState = false, action) {
  let { type } = action;
  switch (type) {
    case 'emptyLegSumbit':
      return true;
    case 'emptyLegClear':
      return false;
    default:
      return preState;
  }
}

// 圣诞活动支付
let christmasInfo = {};
export function getChristmasInfo(preState = christmasInfo, action) {
  let { type, data } = action;
  switch (type) {
    case 'save_christmas_info':
      return data;
    default:
      return preState;
  }
}

const couponInfo = {
  activityState: false, // 活动状态
  couponsShow: false, // 优惠卷弹框
};

export function CouponReducer(state = couponInfo, action) {
  let { type, data } = action;
  switch (type) {
    case TOGGLE_COUPON: {
      return { ...state, ...data };
    }
    default: {
      return state;
    }
  }
}
