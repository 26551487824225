import React, { Component } from 'react'

export default class PrivacyNotice extends Component {
  
  closeTextPromptHandle = () => {
    this.props.closeTextPrompt();
  }

  render () {
    return (
      <div className="home_privacy_policy">
        <div className="text_prompt">
          We use cookies to provide the best browsing experience and keep our site safe.  <a href="/cookies" target="_blank" rel="noreferrer">Learn more.</a>
        </div>
        <span className="accept_btn" onClick={() => { this.closeTextPromptHandle() }}>Accept Cookies</span>
      </div>
    )
  }
}
