import React, { Component } from 'react';
import { Input, Button, Form, message } from 'antd';
import md5 from 'js-md5';
import { setLocalStorage, getLocalStorage, saveUserInfo, sendPendoCode } from '../../untils/auth';
import { googleBound } from '../../services/index';
import store from '../../redux/store';

export default class BoundGoogle extends Component {
  state = {
    getLoading: false,
  };
  onFinish = e => {
    // 校验两次密码是否相同
    if (e.passwordOnce !== e.password) {
      return message.warning('The password is not the same!');
    }
    let params = {
      openId: this.props.openId,
      password: md5(e.passwordOnce),
      rcvEmailFlag: 1,
    };
    this.setState({
      getLoading: true,
    });
    googleBound(params).then(res => {
      if (res.code) {
        if (process.env.REACT_APP_ENV === 'production') sendPendoCode(res.data.userid);
        message.success('successful!');
        let user = {
          name: res.data.name,
          surName: res.data.surName,
          phone: res.data.phone,
          phoneCode: res.data.phoneCode,
          countryIso2: res.data.countryIso2,
          country: res.data.country,
          photo: res.data.photo,
          membershipCardType: res.data.membershipCardType,
          membershipShuttleNumber: res.data.membershipShuttleNumber,
        };
        saveUserInfo(user);
        store.dispatch({
          type: 'token',
          data: res.data.accessToken,
        });
        setLocalStorage('userId', res.data.userid);
        setLocalStorage('time', res.data.accessExpire + '000');
        setLocalStorage('loginType', true);
        this.props.changeLogin();
        // 弹出领卷成功
        if (res.data.newUser) {
          this.props.gainCoupon();
        }
        // 判断是否google 登录
        if (res.data.type !== undefined) {
          setLocalStorage('loginType', true);
        }
        // 详情页
        if (getLocalStorage('details') !== null) {
          this.props.history.push('/payDetails');
        }
        this.setState({
          getLoading: false,
        });
      } else {
        this.setState({
          getLoading: false,
        });
        message.warning(res.msg);
      }
    });
  };
  render() {
    let { getLoading } = this.state;
    return (
      <div className="forgot-password-form">
        <p>Please provide your password</p>
        <span></span>
        <div className="public-form">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input.Password
                prefix={
                  <img
                    src={
                      require('../../assets/imgs/svg/icon_password.svg').default
                    }
                    alt="password icon"
                  />
                }
                placeholder="password"
              />
            </Form.Item>
            <Form.Item
              name="passwordOnce"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input.Password
                prefix={
                  <img
                    src={
                      require('../../assets/imgs/svg/icon_password.svg').default
                    }
                    alt="password icon"
                  />
                }
                placeholder="password"
              />
            </Form.Item>
            <Form.Item>
              <Button loading={getLoading} type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
