/*
  *redux-store
  bill_yang 2021.06
*/
import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import {
  CouponReducer,
  getBlogSearchkeyword,
  getCategoryTags,
  getCharterQuoteChange,
  getChristmasInfo,
  getEmptyLegChange,
  getFuzzySearchData,
  getLoading,
  getLoginShow,
  getMemberDate,
  getToken,
  getTripDate,
} from './reducers/reducers';

// reducers集合
const allReduer = combineReducers({
  coupon: CouponReducer,

  getLoginShow: getLoginShow,
  getLoading: getLoading,
  getToken: getToken,
  getTripDate: getTripDate,
  getMemberDate: getMemberDate,
  getCategoryTags: getCategoryTags,
  getBlogSearchkeyword: getBlogSearchkeyword,
  getFuzzySearchData: getFuzzySearchData,
  getCharterQuoteChange: getCharterQuoteChange,
  getEmptyLegChange: getEmptyLegChange,
  getChristmasInfo: getChristmasInfo,
});

const store = createStore(allReduer, applyMiddleware(thunk));

export default store;
