import React, { Component } from 'react';
import { Input, Button, Form, message } from 'antd';
import { forgetPwd } from '../../services/index';
import store from '../../redux/store';

export default class ForgotPassword extends Component {
  state = {
    actionName: 'Send Link',
  };
  onFinish = e => {
    forgetPwd(e.email).then(res => {
      if (res.code) {
        message.success(
          'The reset email will be sent to your mailbox within 5 minutes. Please check your email to reset your password!'
        );
        this.setState({
          actionName: 'Resend Link',
        });
      } else {
        message.error('Send failure!');
      }
    });
  };
  render() {
    let { actionName } = this.state;
    return (
      <div className="forgot-password-form">
        <div className="back-page" onClick={() => this.props.forgetPwd()}>
          <i className="bg-image"></i>Back to sign in
        </div>
        <p>Forgot password</p>
        <span>
          Please enter your email below and we'll send a message to help you
          reset your password.
        </span>
        <div className="public-form">
          <Form
            name="basic"
            initialValues={{
              remember: true,
            }}
            onFinish={this.onFinish}
            autoComplete="off"
          >
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: '',
                },
              ]}
            >
              <Input
                prefix={
                  <img
                    src={
                      require('../../assets/imgs/svg/icon_email.svg').default
                    }
                    alt="email icon"
                  />
                }
                placeholder="Email Address"
                allowClear
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={store.getState().getLoading}
                type="primary"
                htmlType="submit"
              >
                {actionName}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}
