/*
  *App
  bill_yang 2021.06
*/
import 'antd/dist/antd.css';
import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Layout from './components/layout/Layout'; //主页布局
import { mainRoutes, tokenRouter, userRouter } from './routes/index'; // 路由
import { setLocalStorage } from './untils/auth';
// import Loading from './components/public/Loading';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { connect } from 'react-redux';
import Personal from './components/layout/Personal';
import Loading from './pages/state/Loading';

// 懒加载
// const loading = () => <div className="loading-lazy"></div>;
// 登录页
const Login = React.lazy(() => import('./pages/login/Login'));
// 404
const Page404 = React.lazy(() => import('./pages/notFoundPage/Page404'));
// 支付加载
const PayLoading = React.lazy(() => import('./pages/state/PayLoading'));
// 微信加载
const WxLoading = React.lazy(() => import('./pages/state/WxLoading'));
// 详情页
const ResultsDetails = React.lazy(() => import('./pages/results/ResultsDetails'));
// Create a client
const queryClient = new QueryClient();
// 路由配置
class App extends React.Component {
  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <BrowserRouter>
          <React.Suspense fallback={<Loading />}>
            <Switch>
              {/* 登录页 */}
              <Route exact path="/login" render={(routeProps) => <Login {...routeProps} />} />
              {/* 404 */}
              <Route exact path="/404" render={(routeProps) => <Page404 {...routeProps} />} />
              {/* 支付加载 */}
              <Route
                exact
                path="/payLoading"
                render={(routeProps) => <PayLoading {...routeProps} />}
              />
              {/* 微信登录加载 */}
              <Route
                exact
                path="/wxLoading"
                render={(routeProps) => <WxLoading {...routeProps} />}
              />
              {/* 主路由 */}
              <Layout>
                <Switch>
                  {/* 详情 */}
                  <Route
                    exact
                    path="/resultsDetails/:routId"
                    render={(routeProps) => <ResultsDetails {...routeProps} />}
                  />
                  {mainRoutes.map((route) => {
                    return (
                      <Route
                        path={route.path}
                        key={route.path}
                        exact
                        render={(routeProps) => {
                          return <route.component {...routeProps} />;
                        }}
                      />
                    );
                  })}
                  {!this.props.getToken ? (
                    <Redirect to="/" />
                  ) : (
                    tokenRouter.map((route, index) => {
                      return (
                        <Route
                          key={index}
                          path={route.path}
                          exact
                          render={(routeProps) => {
                            return <route.component {...routeProps} />;
                          }}
                        />
                      );
                    })
                  )}
                  <Personal>
                    {!this.props.getToken ? (
                      <Redirect to="/" />
                    ) : (
                      userRouter.map((route, index) => {
                        return (
                          <Route
                            key={index}
                            path={route.path}
                            exact
                            render={(routeProps) => {
                              return <route.component {...routeProps} />;
                            }}
                          />
                        );
                      })
                    )}
                  </Personal>
                  <Redirect to="/404" />
                </Switch>
              </Layout>
            </Switch>
          </React.Suspense>
        </BrowserRouter>
      </QueryClientProvider>
    );
  }
}
// react-redux状态
const mapStateToProps = (state) => {
  return {
    getLoading: state.getLoading,
    getToken: state.getToken,
  };
};
export default connect(mapStateToProps)(App);
