/*
 *接口
 */
import request from '../untils/request';

// 活动
export function getActivity() {
  return request({
    method: 'get',
    url: `/module/activity`,
  });
}

// 优惠卷
export function couponsList(id) {
  return request({
    method: 'get',
    url: `/users/${id}/coupons`,
  });
}

// 优惠码
export function couponsCodeList(params = {}) {
  return request({
    method: 'post',
    url: `/order/discount/legal`,
    params,
  });
}

// 获取首页banner轮播
export function getIndexBanner() {
  return request({
    method: 'get',
    url: `/module/banner?device=1`,
  });
}

// 首页热门航班
export function flightsHot() {
  return request({
    method: 'get',
    url: `/module/flights/hot`,
  });
}

// 航班详情
export function resultsDetails(data) {
  return request({
    method: 'get',
    url: `/module/flights/Detail?id=${data.id}`,
  });
}

// charterQuote页面的模糊搜索
export function getSearchPrompt(params = {}) {
  return request({
    method: 'get',
    url: `/functionality/search/fuzzy_airport`,
    params,
  });
}

// charterQuote页面的搜索机场
export function getSearchDetails(data) {
  return request({
    method: 'get',
    url: `/functionality/search/airports/surrounding?longitude=${data.long}&latitude=${data.lati}`,
  });
}

// charterQuote页面的获取包机列表
export function getCharterQuote(params = {}) {
  return request({
    method: 'get',
    url: `/module/charter_quote`,
    params,
  });
}

// 根据IP搜索周边机场
export function getLocationAirport(data) {
  return request({
    method: 'get',
    url: `/functionality/search/airports/ip`,
    data,
  });
}

// 定期航班机场列表
export function getScheduled() {
  return request({
    method: 'get',
    url: '/scheduled/flights',
  });
}

// 定期航班城市列表
export function getScheduledCity() {
  return request({
    method: 'get',
    url: `/scheduled/search-abstract`,
  });
}

// 定期航班搜索列表
export function searchScheduled(params = {}) {
  return request({
    method: 'get',
    url: '/scheduled/routes',
    params,
  });
}

// 获取定期航班订单详情
export function getFlightOrderDetails(id) {
  return request({
    method: 'get',
    url: `/scheduled/detailed?orderId=${id}`,
  });
}

// 定期航班跳转最新blog
export function getNewBlog() {
  return request({
    method: 'get',
    url: `/blogs/latest`,
  });
}

// 用户反馈
export function userNeeds(data) {
  return request({
    method: 'post',
    url: `/module/user_needs`,
    data,
  });
}

// 获取首页blog
export function getIndexBlog() {
  return request({
    method: 'get',
    url: `/blogs/advance/home`,
  });
}

// blog页面获取导航分类标签
export function getBlogCategoryTags() {
  return request({
    method: 'get',
    url: `/blogs/advance/tags`,
  });
}

// blog页面获取不同分类的博客列表
export function getBlogList(categoryId, page) {
  return request({
    method: 'get',
    url: `/blogs/advance/category?categoryId=${categoryId}&page=${page}`,
  });
}

// blog页面搜索博客
export function getSearchBlog(key, page) {
  return request({
    method: 'get',
    url: `/blogs/advance/search?key=${key}&page=${page}`,
  });
}

// blog页面获取blog详情
export function getBlogDetails(id, options = {}) {
  return request({
    method: 'get',
    url: `/blogs/${id}?blogId=${id}`,
    ...options,
  });
}

//首页空腿推荐
export function getPreferentialFlights() {
  return request({
    method: 'get',
    url: `/module/preferential_flights`,
  });
}

//首页shared flights
export function getSharedFlights() {
  return request({
    method: 'get',
    url: `/module/shared-routes`,
  });
}

//空腿热门城市导航
export function getHotCity() {
  return request({
    method: 'get',
    url: `/module/hot_city_list`,
  });
}

//空腿列表
export function getEmptyLegList() {
  return request({
    method: 'get',
    url: `/module/empty_leg`,
  });
}

//获取城市对应的空腿列表
export function getCityEmptyLegList(cityName) {
  return request({
    method: 'get',
    url: `/module/empty_leg_city_search?city=${cityName}`,
  });
}

//搜索空腿
export function getSearchEmptyLegList(data) {
  return request({
    method: 'get',
    url: `/module/empty_leg_search?startAirportId=${data.startAirportId}&endAirportId=${data.endAirportId}&startDate=${data.startDate}&endDate=${data.endDate}&startIdType=${data.startIdType}&endIdType=${data.endIdType}`,
  });
}

//空腿详情
export function getEmptyLegDetail(id) {
  return request({
    method: 'get',
    url: `/module/empty_leg_detail_page?emptyLegID=${id}`,
  });
}

//提交空腿询价单
export function userInquire(data) {
  return request({
    method: 'post',
    url: `/module/user_inquire`,
    data,
  });
}

// Inquire列表
export function getMyInquireList(id) {
  return request({
    method: 'get',
    url: `/module/user_inquire_list?userID=${id}`,
  });
}

// Inquire详情
export function getMyInquireDetails(id) {
  return request({
    method: 'get',
    url: `/module/find_one_periphery_info?inquireID=${id}`,
  });
}

// 订单列表
export function getOrderList() {
  return request({
    method: 'get',
    url: '/orders',
  });
}

// 订单详情
export function getOrderDetails(id) {
  return request({
    method: 'get',
    url: `/orders/${id}`,
  });
}

// 获取个人信息
export function getInfo(id) {
  return request({
    method: 'get',
    url: `/users/${id}`,
  });
}

// 编辑个人信息
export function editInfo(id, data) {
  return request({
    method: 'put',
    url: `/users/${id}`,
    data,
  });
}

// 修改密码
export function changePwd(id, data) {
  return request({
    method: 'post',
    url: `/users/${id}/modify_pwd`,
    data,
  });
}

// 订单添加乘客
export function editOrderDetails(id, data) {
  return request({
    method: 'post',
    url: `/order/update/${id}`,
    data,
  });
}

// 乘客列表
export function getPassengersList(id) {
  return request({
    method: 'get',
    url: `/users/${id}/passengers`,
  });
}

// 单个乘客
export function getPassenger(id, data) {
  return request({
    method: 'get',
    url: `/users/${id}/passenger?passengerId=${data}`,
  });
}

// 添加乘客
export function addPassengers(id, data) {
  return request({
    method: 'post',
    url: `/users/${id}/passenger`,
    data,
  });
}

// 更新乘客
export function UpdatePassenger(id, data) {
  return request({
    method: 'put',
    url: `/users/${id}/passenger`,
    data,
  });
}

// 删除乘客
export function delPassengers(id, data) {
  return request({
    method: 'delete',
    url: `/users/${id}/passenger?passengerId=${data}`,
    // data,
  });
}

// 进入支付通道
export function pay(params = {}) {
  return request({
    method: 'get',
    url: `/order/trypay`,
    params,
  });
}

// 支付
export function payState(data) {
  return request({
    method: 'post',
    url: `/order/pay`,
    data,
  });
}

// 信用卡支付 热门航线
export function payCredit(params = {}) {
  return request({
    method: 'get',
    url: `/order/payment_method/pay`,
    params,
  });
}

// 信用卡支付 定期航班
export function payCreditScheduled(params = {}) {
  return request({
    method: 'post',
    url: `/scheduled/payment/method`,
    params,
  });
}

// 取消订单
export function refund(data) {
  return request({
    method: 'post',
    url: `/order/cancle`,
    data,
  });
}

// 登录
export function login(data) {
  return request({
    method: 'get',
    url: `/users/login/common?account=${data.account}&password=${data.password}`,
  });
}

// 注册
export function register(data) {
  return request({
    method: 'post',
    url: `/account/register`,
    data: data,
  });
}

// 激活账号-2.7.2中废弃
export function reactivateAccount(data) {
  return request({
    method: 'get',
    url: `/account/reactivate?account=${data}`,
    data,
  });
}

// 重置密码
export function forgetPwd(data) {
  return request({
    method: 'get',
    url: `/account/password/reset_email?account=${data}`,
  });
}

// 发送验证码
export function verificationCode(account) {
  return request({
    method: 'get',
    url: `/account/v_code?account=${account}`,
  });
}

// google登录
export function googleLogin(data) {
  return request({
    method: 'get',
    url: `/users/login/google?token=${data}&rcvEmailFlag=1`,
  });
}

// facebook登录
export function facebookLogin(token) {
  return request({
    method: 'get',
    url: `/users/login/facebook?token=${token}&rcvEmailFlag=1`,
  });
}

// 微信登录
export function loginWx(code) {
  return request({
    method: 'get',
    url: `/users/login/wx?code=${code}&loginType=2`,
  });
}

// 补全google信息
export function googleBound(data) {
  return request({
    method: 'put',
    url: `/users/account/google`,
    data,
  });
}

// facebook绑定邮箱
export function facebookBoundEmail(data) {
  return request({
    method: 'put',
    url: `/users/account/facebook/email`,
    data,
  });
}

// 微信绑定
export function wxBound(data) {
  return request({
    method: 'put',
    url: `/user/account/wx`,
    data,
  });
}

// 联系我们
export function contactUs(data) {
  return request({
    method: 'post',
    url: `/contact`,
    data: data,
  });
}

/**----- old api ----- */
// 搜索提示详情
export function getResultsDetails(name, type, cityId) {
  return request({
    method: 'get',
    url: `/flights/prompt/detail?name=${name}&type=${type}&cityId=${cityId}`,
  });
}
// 模糊搜索提示
export function getPrompt(data) {
  return request({
    method: 'get',
    url: `/flights/prompt?key=${data}`,
  });
}
// 获取30公里内机场
export function getSearchAirport(data) {
  return request({
    method: 'get',
    url: `/searchAirport?longi=${data.longi}&latit=${data.latit}`,
  });
}
// 获取飞机类别
export function getAircraft() {
  return request({
    method: 'get',
    url: `/config/aircraft/engine`,
  });
}
// 搜索结果列表
export function getResultsList(data) {
  return request({
    method: 'get',
    url: `/flights?start=${data.start}&end=${data.end}&seat=${data.seat}&model=${data.model}&minPrice=${data.minPrice}&maxPrice=${data.maxPrice}&engine=${data.engine}`,
  });
}
/**-----old api end ----- **/

// 用户注册来源
export function getUser(data) {
  return request({
    method: 'post',
    url: `/module/user_source/${data.id}?source=${data.source}`,
    data,
  });
}
// 订单来源
export function getOrder(data) {
  return request({
    method: 'post',
    url: `/module/order_source/${data.id}?source=${data.source}`,
    data,
  });
}
// 空腿询价单来源
export function getInquire(data) {
  return request({
    method: 'post',
    url: `/module/inquire_source/${data.id}?source=${data.source}`,
    data,
  });
}
// 包机询价单来源
export function getCharter(data) {
  return request({
    method: 'post',
    url: `/module/charter_source/${data.id}?source=${data.source}`,
    data,
  });
}

// 会员提交信息
export function getMember(params = {}) {
  return request({
    method: 'post',
    url: `/member/consultation`,
    params,
  });
}

// 定期航班支付
export function searchFlightPay(data) {
  return request({
    method: 'post',
    url: `/scheduled/pay`,
    data,
  });
}

// 定期航班支付回调
export function searchFlightPayState(data) {
  return request({
    method: 'post',
    url: `/scheduled/paycall`,
    data,
  });
}

// 定期航班订单详细信息
export function getScheduledDetails(id) {
  return request({
    method: 'get',
    url: `/scheduled/detailed/${id}`,
  });
}

// 定期航班订单当月售卖
export function getScheduledMonth(params = {}) {
  return request({
    method: 'get',
    url: `/scheduled/month`,
    params,
  });
}

// 信用卡查询支付方式
export function getPayment() {
  return request({
    method: 'get',
    url: `/order/payment/method`,
  });
}

// 定期航班popular 列表
export function getScheduledList(page, pageSize) {
  return request({
    method: 'get',
    url: `/scheduled/abstract?page=${page}&pageSize=${pageSize}`,
  });
}
