import Tap from "@tapfiliate/tapfiliate-js";
import { ConfigProvider } from "antd";
import React from "react";
import ReactDOM from "react-dom";
import { HelmetProvider } from 'react-helmet-async';
import HeaderMeta from 'components/headerMeta/HeaderMeta';
import { Provider } from "react-redux";
import App from "./App";
import "./assets/style/public_style.scss";
import store from "./redux/store";

Tap.init(process.env.REACT_APP_TAP_ACCOUNT_ID);
ReactDOM.render(
  <HelmetProvider>
      <Provider store={store}>
        <ConfigProvider
          getPopupContainer={(node) => {
            if (node) {
              return node.parentNode;
            }
            return document.body;
          }}
        >
          <HeaderMeta type='default' />
          <App />
        </ConfigProvider>
      </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
